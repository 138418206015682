import React from 'react';
import st from './footer.module.scss';
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
   return (
      <footer className={st.footer}>
         <div className='wrapper'>
            <div className={st.box__cont}>
               <div>
                  <h3 className={st.box__title}>About Us</h3>
                  <p>L.A. Dance Floor - rental source for dance floors. We do the work quickly and efficiently</p>
                  <ul className={st.icon__menu}>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-facebook-f"></i>
                        </Link>
                     </li>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-instagram"></i>
                        </Link>
                     </li>
                     <li>
                        <Link to={'https://www.instagram.com/ladancefloorinc/'} target={'_blank'}>
                           <i className="fa-brands fa-youtube"></i>
                        </Link>
                     </li>
                  </ul>
               </div>
               <div className={st.second__box}>
                  <h3 className={st.box__title}>Contact Info</h3>
                  <p>
                     <span><i className="fa-solid fa-location-dot"></i>  Address: </span> 1706 Standard Ave Glendale CA 91201 </p>
                  <p className={st.text__marg}>
                     <span><i className="fa-solid fa-globe"></i> Website: </span> <Link to={'/'}>ladancefloor.com</Link> </p>
                  <p className={st.text__marg}>
                     <span><i className="fa-solid fa-envelope"></i> Email: </span> ladancefloorinc@gmail.com</p>
                  <p className={st.text__marg}><span><i className="fa-solid fa-phone"></i> Phone: </span> 818.510.1302</p>
               </div>
            </div>
         </div>
      </footer>
   )
}

export default Footer
