import axios from "axios";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IHeaders, LINKS } from '../../types/types';

const headers: IHeaders = {
   "Content-Type": "text/json"
};


export const getLogin = createAsyncThunk(
   'get/login',
   async (obj: string, thunkAPI) => {
      const response = await axios.post(LINKS.LOGIN, obj, { headers: headers })
         .then((r) => {
            return r.data
         })
         .catch((err) => {
            console.log(err)
            return thunkAPI.rejectWithValue(err)
         })
      return response
   }
)

export const addPictures = createAsyncThunk(
   'add/pics',
   async (obj: any, thunkAPI) => {
      const response = await axios.post(LINKS.ADDPICTURES, obj, { headers: headers })
         .then((r) => {
            return r.data
         })
         .catch((err) => {
            console.log(err)
            return thunkAPI.rejectWithValue(err)
         })
      return response
   }
)

export const getPictures = createAsyncThunk(
   'get/pic',
   async () => {
      const response = await axios.get(LINKS.GETPICTURE, { headers: headers })
         .then(async (r: any) => {
            return r.data
         })
         .catch((err) => {
            console.log(err)
         })
      return response
   }
)

export const deletePictureFromBase = createAsyncThunk(
   'delete/pic',
   async (num: string, thunkAPI) => {
      const response = await axios.post(LINKS.DELETEPICTURE, num, { headers: headers })
         .then((r) => {
            return r.data
         })
         .catch((err) => {
            console.log(err)
            return thunkAPI.rejectWithValue(err)
         })
      return response
   }
)


