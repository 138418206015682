import React from 'react'
import st from './about.module.scss'
import cn from 'classnames'

const About: React.FC = () => {
  return (
    <section>
      <div className={'wrapper'}>
        <div className={st.container}>
          <p className={st.text}>
            <b>L.A. Dance Floor Inc</b> - is a dance floor company that specializes in dance floors of any color, decors and monograms.
            <br /><br /><br />
            L.A. dance floor is seamless dance floor with super gloos, non slippery, German mode Laminate. <br /><br /><br />
            We do customs orders for any colors and finishes, matte or gloss. <br /><br /><br />
            Each pance size is 3ff by 4ff. <br /><br /><br />
            We do serve through out L.A. and Orange County.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About
