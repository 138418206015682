import React, { useState, useEffect } from 'react'
import st from './navbar.module.scss';
import cn from 'classnames';
import { NavLink } from "react-router-dom";
import useSessionStorage from '../../useSessionStorage';


const NavBar: React.FC = () => {
   const session = useSessionStorage('id');
   const [colorNav, setColorNav] = useState<boolean>(false);
   const changeColor = (): void => {
      if (window.scrollY >= 10) {
         setColorNav(true);
      } else {
         setColorNav(false);
      }
   }
   window.addEventListener('scroll', changeColor);

   const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false)

   useEffect(() => {
      document.body.classList.toggle('lock', isBurgerOpen)
   }, [isBurgerOpen])

   return (
      <>
         <nav className={cn({
            [st.navbar]: true,
            [st.navbar__sticky]: colorNav,
            [st.navbar__sticky__burg]: isBurgerOpen
         })}>
            <div className={cn('wrapper', st.navbar__wrapper)}>
               <div>
                  <h2 className={st.logo__text}>L.A. Dance Floor</h2>
               </div>
               <div className={st.menu__box}>
                  <ul className={cn(st.menu)}>
                     <li>
                        <NavLink
                           to={'/'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                           end
                        >
                           home
                        </NavLink>
                     </li>
                     <li
                     >
                        <NavLink
                           to={'/about'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                        >
                           about
                        </NavLink>
                     </li>
                     <li>
                        <NavLink
                           to={'/services'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                        >
                           services
                        </NavLink>
                     </li>
                     <li>
                        <NavLink
                           to={'/gallery'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                        >
                           gallery
                        </NavLink>
                     </li>
                     <li>
                        <NavLink
                           to={'/contact'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                        >
                           contact us
                        </NavLink>
                     </li>
                     {session != null && <li>
                        <NavLink
                           to={'/admin'}
                           style={({ isActive }: { isActive: boolean }) => isActive && colorNav || isActive && !colorNav ? { color: 'rgb(204, 148, 50)' } : !isActive && colorNav ? { color: 'white' } : { color: 'black' }}
                        >
                           admin
                        </NavLink>
                     </li>}
                  </ul>
               </div>
               <div className={st.burger}>
                  <i
                     className={!isBurgerOpen ? "fa-solid fa-bars" : "fa-solid fa-xmark"}
                     onClick={() => setIsBurgerOpen(!isBurgerOpen)}
                  >
                  </i>
               </div>
               <div className={cn({
                  [st.burger__box]: true,
                  [st.burger__box_active]: isBurgerOpen
               })}>
                  <ul className={st.burger__menu}>
                     <li onClick={() => setIsBurgerOpen(false)}>
                        <NavLink
                           to={'/'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                           end
                        >
                           home
                        </NavLink>
                     </li>
                     <li
                        onClick={() => setIsBurgerOpen(false)}
                     >
                        <NavLink
                           to={'/about'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                        >
                           about
                        </NavLink>
                     </li>
                     <li onClick={() => setIsBurgerOpen(false)}>
                        <NavLink
                           to={'/services'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                        >
                           services
                        </NavLink>
                     </li>
                     <li onClick={() => setIsBurgerOpen(false)}>
                        <NavLink
                           to={'/gallery'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                        >
                           gallery
                        </NavLink>
                     </li>
                     <li onClick={() => setIsBurgerOpen(false)}>
                        <NavLink
                           to={'/contact'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                        >
                           contact us
                        </NavLink>
                     </li>
                     {session != null && <li onClick={() => setIsBurgerOpen(false)}>
                        <NavLink
                           to={'/admin'}
                           style={({ isActive }: { isActive: boolean }) => isActive ? { color: 'rgb(204, 148, 50)' } : {}}
                        >
                           admin
                        </NavLink>
                     </li>}
                  </ul>
               </div>
            </div>
         </nav>
      </>
   )
}

export default NavBar
