import React from 'react'
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../HomePage/Footer';
import NavBar from '../NavBar';
import st from './out.module.scss'


const Out: React.FC = () => {
  const { pathname } = useLocation()

  return (
    <div className={st.app__main}>
      <header className={st.header}>
        <div className={st.nav__container}>
          <NavBar />
        </div>
        <div className={st.text__container}>
          <h2 className={st.text}>{pathname.slice(1)}</h2>
        </div>
      </header>
      <main className={st.main}><Outlet /></main>
      <Footer />
    </div>
  )
}

export default Out
