import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import contactReducer from '../features/Contact/ContactSlice';
import loginReducer from '../features/Login/LoginSlice';

export const store = configureStore({
  reducer: {
    contact: contactReducer,
    login: loginReducer
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
