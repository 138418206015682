import { Navigate, useRoutes } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import About from "../../pages/About"
import Contact from "../../pages/Contact"
import Gallery from "../../pages/Gallery"
import ServicesPage from "../../pages/ServicesPage"
import Login from "../Login"
import AdminPage from "../AdminPage"
import HomePage from "../HomePage"
import Out from "../Out"

const Routes = () => {   
   const routing = useRoutes([
      {path: '*', element: <h1>Page not found</h1>},
      {path: '/', element: <HomePage/>},
      {path: '', element: <Out/>, children: [
         {path: '/about', element: <About/>},
         {path: '/services', element: <ServicesPage/>},
         {path: '/gallery', element: <Gallery/>},
         {path: '/contact', element: <Contact/>},
      ]},
      {path: '/login', element: <Login/>},
      {path: '/admin', element: <AdminPage/>}
   ])
   return routing
}

export default Routes