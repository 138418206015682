import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginSliceState } from '../../types/types';
import { getLogin, getPictures, addPictures } from './LoginAPI';

const initialState:LoginSliceState = {
   loginAdmin: {},
   response: false,
   getSession: '',
   uploadDone: {},
   allPics: [],
   responsePicDownload: false,
}

const loginSlice = createSlice({
   name: 'login',
   initialState,
   reducers: {
      deletePicture: (state, action: PayloadAction<number>) => {
         state.allPics = state.allPics.filter((a: any) => a.id != action.payload)
      }
   },
   extraReducers(builder) {
      builder.addCase(getLogin.fulfilled, (state: any, action) => {
         state.loginAdmin = action.payload
         state.response = true
         if (action.payload.id) {
            sessionStorage.setItem('id', action.payload.id);
         }
      })
      builder.addCase(getPictures.fulfilled, (state: any, action) => {
         state.allPics = action.payload
         state.responsePicDownload = true
      })
      builder.addCase(addPictures.fulfilled, (state: any, action) => {
         state.uploadDone = action.payload
      })
   }
})

export default loginSlice.reducer
export const {deletePicture} = loginSlice.actions