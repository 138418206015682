import React from 'react'
import Services from '../../components/Services'
import st from './servicepage.module.scss'
import cn from 'classnames'

const ServicesPage: React.FC = () => {
  return (
    <section className={st.section}>
      <div className={'wrapper'}>
        <div className={st.about__container}>
          <h4 className={st.text__primary}>
            L.A. Dance Floor - dance floor rental source that serve throughout LA Country, Orange County, etc.
          </h4>
          <p><i className="fa-solid fa-circle-check"></i> Create modern and high-quality dance floors for all events </p>
          <p><i className="fa-solid fa-circle-check"></i> Offer a large collection for every occasion </p>
          <p><i className="fa-solid fa-circle-check"></i> Customers will always be happy for the service and quality product</p>
          <p><i className="fa-solid fa-circle-check"></i> Custom designs</p>
          <p><i className="fa-solid fa-circle-check"></i> Have quite a lot of experience </p>
          <p><i className="fa-solid fa-circle-check"></i> Do the work quickly and efficiently</p>
          <p><i className="fa-solid fa-circle-check"></i> Promise you an unforgettable dance floor for all occasions </p>
        </div>
      </div>
    </section>
  )
}

export default ServicesPage
