export const gallery: any = [
   {
      original: 'images/slider/1b.jpeg',
      thumbnail: 'images/slider/1b.jpeg',
      thumbnailHeight: '100%',
   },
   {
      original: 'images/slider/2b.jpeg',
      thumbnail: 'images/slider/2b.jpeg',
      thumbnailHeight: '100%',
   },
   {
      original: 'images/slider/3b.jpeg',
      thumbnail: 'images/slider/3b.jpeg',
      thumbnailHeight: '100%',
      loading: 'lazy'
   },
   {
      original: 'images/slider/4b.jpeg',
      thumbnail: 'images/slider/4b.jpeg',
      thumbnailHeight: '100%',
      loading: 'lazy'
   },
   {
      original: 'images/slider/5b.jpeg',
      thumbnail: 'images/slider/5b.jpeg',
      thumbnailHeight: '100%',
      loading: 'lazy'
   },
   {
      original: 'images/slider/6b.jpeg',
      thumbnail: 'images/slider/6b.jpeg',
      thumbnailHeight: '100%',
      loading: 'lazy'
   },
   {
      original: 'images/slider/7b.jpeg',
      thumbnail: 'images/slider/7b.jpeg',
      thumbnailHeight: '100%',
      loading: 'lazy'
   },
]