import React from 'react'
import ContactInformation from '../../components/ContactInformation'
import st from './contact.module.scss'

const Contact: React.FC = () => {
  return (
    <section className={st.section}>
      <div className="wrapper">
        <ContactInformation />
      </div>
      <div className={st.map__container}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2300.8078895965314!2d-118.29344698719568!3d34.16778299907693!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c005ffdeaf1b%3A0x4720ac41c450de40!2s1706%20Standard%20Ave%2C%20Glendale%2C%20CA%2091201%2C%20USA!5e0!3m2!1sen!2sru!4v1681637004622!5m2!1sen!2sru" width="100%" height="100%" loading="lazy" ></iframe>
      </div>
    </section>
  )
}

export default Contact
