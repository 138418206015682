import React from 'react';
import { BrowserRouter } from "react-router-dom";
import Routes from './components/router/routes';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
