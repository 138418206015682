import React from 'react'
import { useForm } from 'react-hook-form'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { addPictures } from '../../features/Login/LoginAPI'
import st from './adminpage.module.scss'
import useSessionStorage from '../../useSessionStorage';

const AdminPage: React.FC = () => {
  const {uploadDone} = useAppSelector((state: any) => state.login)
   const {register, handleSubmit, formState: {errors}} = useForm()
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const session = useSessionStorage('id');

  const save = async(pic: any) => {
    const formData = new FormData()
    formData.append('file', pic.img[0]);
    await dispatch(addPictures(formData))
  }

  const logout = () => {
    sessionStorage.clear();
    navigate('/');
  }


  return (
    <>
    {session != null ? <>
      <header className={st.header}>
        <nav className={st.navbar}>
          <ul className={st.navbar__menu}>
            <li className={st.navbar__item}><Link to={'/'} className={st.navbar__link}>Home</Link></li>
            <li className={st.navbar__item}><Link to={'/gallery'} className={st.navbar__link}>Gallery</Link></li>
            <li className={st.navbar__item}><span onClick={() => logout()}>Logout</span></li>
          </ul>
        </nav>
      </header>
    <div className={st.content}>
      <form onSubmit={handleSubmit(save)}>
        <div><label htmlFor="">Upload Picture</label></div>
        {errors.img && <p className={st.error}>No file</p>}
        <div><input type="file" {...register('img', {required: true})} className={st.form__input}/></div>
        <div><button className={st.form__btn}>Save</button></div>
      </form>
      {uploadDone.message && <p>{uploadDone.message}</p>}
    </div></> : <Navigate to={'/'}/>}
    </>
  )
}

export default AdminPage
