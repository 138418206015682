import React, { useEffect } from 'react'
import BoxImage from '../../BoxImage';
import ContactInformation from '../../ContactInformation';
import Services from '../../Services';
import st from './main.module.scss';
import cn from 'classnames'
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getPictures } from '../../../features/Login/LoginAPI';
import { Link } from 'react-router-dom';



const Main: React.FC = () => {
   const { allPics, responsePicDownload } = useAppSelector((state: any) => state.login);
   const dispatch = useAppDispatch();

   useEffect(() => {
      dispatch(getPictures());
   }, [])

   return (
      <main>
         <section className={st.first__section}>
            <div className={cn('wrapper')}>
               <div className={st.title__container}>
               <h3 className={st.title}>L.A. Dance Floor Inc - is a dance floor company that specializes in dance floors of any color, decors and monograms</h3>
               <Link to={'/about'}>See more</Link>
               </div>
            </div>
         </section>
         <section className={st.second__section}>
            <div className="wrapper">
               <h3 className={st.title}>Our Gallery</h3>
               <div className={st.box__container}>
                  {!responsePicDownload ? <h3>Loading...</h3> : allPics && allPics.slice(0, 9).map((elem: any, index: number) =>
                  (<BoxImage
                     key={elem.id}
                     image={elem.pic_name}
                     index={index}
                  />)
                  )}
               </div>
               <div className={st.box__more}><Link className={st.box__more_link} to={'/gallery'}>See all pictures</Link></div>
            </div>
         </section>
         <section className={st.last__section}>
            <div className="wrapper">
               <ContactInformation />
            </div>
         </section>
      </main>
   )
}

export default Main
