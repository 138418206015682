import React from 'react'
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { sendContacts } from '../../features/Contact/ContactAPI';
import { addMessage } from '../../features/Contact/ContactSlice';
import { ContactForm } from '../../types/types';
import st from './contactinform.module.scss';

const ContactInformation: React.FC = () => {
   const { register, handleSubmit, formState: { errors }, reset } = useForm<ContactForm>()
   const dispatch = useAppDispatch();
   const { contactMessage, allMessages } = useAppSelector((state: any) => state.contact)

   const save = (data: ContactForm): void => {
      dispatch(addMessage(data))
      if (allMessages.length < 3) {
        dispatch(sendContacts(data));  
      }
      reset();
   }

   return (
      <div className={st.box__container}>
         <div className={st.box}>
            <p>DON'T HESITATE TO CONTACT US</p>
            <h3 className={st.box__title}>Contact Information</h3>
            <div className={st.box__boxes}>
               <div className={st.box__box}> <i className="fa-solid fa-address-book"></i> 1706 Standard Ave Glendale CA 91201</div>
               <div className={st.box__box}> <i className="fa-solid fa-headphones"></i> 818.510.1302</div>
               <div className={st.box__box}> <i className="fa-solid fa-globe"></i> ladancefloor.com</div>
               <div className={st.box__box}> <i className="fa-solid fa-envelope"></i> ladancefloorinc@gmail.com </div>
            </div>
         </div>
         <div className={st.box}>
            <p>CONTACT US</p>
            <h3 className={st.box__title}>Get In Touch With Us</h3>
            <p className={st.box__text}>* fields are required</p>
            <div className={st.box__form__container}>
               <form onSubmit={handleSubmit(save)}>
                  <div>
                     {errors.name && <p className={st.error__text}>The name must consist of letters and not more than 30</p>}
                     <input
                        type="text"
                        placeholder='Name*'
                        {...register('name', { required: true, pattern: /^[a-zA-Z]+$/, maxLength: 30 })}
                     />
                  </div>
                  <div>
                     {errors.email && <p className={st.error__text}>Wrong email</p>}
                     <input
                        type="text"
                        placeholder='Email*'
                        {...register('email', { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, maxLength: 30 })}
                     />
                  </div>
                  <div>
                     {errors.subject && <p className={st.error__text}>Subject max length should be 50</p>}
                     <input
                        type="text"
                        placeholder='Subject*'
                        {...register('subject', { required: true, maxLength: 50 })}
                     />
                  </div>
                  <div>
                     {errors.textarea && <p className={st.error__text}>Message max length should be 50</p>}
                     <input
                        type='text'
                        placeholder='Message*'
                        className={st.textarea}
                        {...register('textarea', { required: true, maxLength: 200 })}
                     />
                  </div>
                  <div>
                     <button>Send</button>
                     {contactMessage.sent && <p>{contactMessage.sent}</p>}
                  </div>
               </form>
            </div>
         </div>
      </div>
   )
}

export default ContactInformation
