import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LINKS } from "../../types/types";

export const sendContacts = createAsyncThunk(
   'send/contacts',
   async(obj: any) => {
      const contact = axios.post(LINKS.CONTACT, obj, {
         headers: {
            'Content-Type': 'application/json'
         }
      })
      .then((r) => {
         return r.data
      })
      .catch((err: any) => {
         console.log(err)
      })
      return contact
   }
)