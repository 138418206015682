import { useState } from "react"

const useSessionStorage = (key: string): string | null => {
   const getValue = () => {
      const storage: string | null = sessionStorage.getItem(key);
      if(storage) {
        return JSON.parse(storage)
      } 
      return null;
   }

   const [value, setValue] = useState<string | null>(getValue)

   return value
}

export default useSessionStorage