import React, { useState, useEffect } from 'react';
import st from './header.module.scss';
import cn from 'classnames';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import NavBar from '../../NavBar';
import {gallery} from '../../../gallery';


const Header: React.FC = () => {
   return (
      <header className={st.header}>
         <NavBar />
         <div className={cn('wrapper', st.imagegallery__wrapper)}>
            <ImageGallery
               items={gallery}
               thumbnailPosition={'right'}
               showPlayButton={false}
               showThumbnails={true}
               showFullscreenButton={true}
            />
         </div>
      </header>
   )
}

export default Header
