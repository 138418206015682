import React, { useState, useEffect, useRef } from 'react'
import st from './boximage.module.scss';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { deletePicture } from '../../features/Login/LoginSlice';
import { deletePictureFromBase } from '../../features/Login/LoginAPI';
import useSessionStorage from '../../useSessionStorage';
import { BoxImageType } from '../../types/types';

const BoxImage: React.FC<BoxImageType> = ({ image, index, id }) => {
  const session = useSessionStorage('id');
  const { allPics } = useAppSelector((state: any) => state.login);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [imgInd, setImgInd] = useState<number>(0);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.classList.toggle('lock', isModalOpen);
  }, [isModalOpen])

  const clickModal = (ind: number): void => {
    setIsModalOpen(true);
    setImgInd(ind);
  }

  const nextSlide = (): void => {
    setImgInd(prev => prev != allPics.length - 1 ? prev + 1 : 0)
  }

  const prevSlide = (): void => {
    setImgInd(prev => prev != 0 ? prev - 1 : allPics.length - 1)
  }

  const handleClickOutsider = (e: any) => {
    if (isModalOpen && e.target == modalRef.current) {
      setIsModalOpen(false);
    }
  }
  window.addEventListener('click', handleClickOutsider)

  const remove = (id: number): void => {
    dispatch(deletePicture(id))
    dispatch(deletePictureFromBase(JSON.stringify(id)))
  }

  return (
    <>
      <div className={st.gallery__box}>
        <div className={st.gallery__box_img}>
          <LazyLoadImage
            src={image}
            alt="pic"
            width={'100%'}
            height={'100%'}
            effect="blur" />
        </div>
        <div className={st.hover__color}>
          <button
            className={st.btn}
            onClick={() => clickModal(index)}
          >
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        {session != null && <button onClick={() => remove(id!)} className={st.btn__delete}>delete</button>}
      </div>

      {isModalOpen && (
        <div className={st.modal} ref={modalRef}>
          <div className={st.modal__box}>
            <LazyLoadImage
              src={allPics[imgInd].pic_name}
              alt="pic"
              width={'100%'}
              height={'100%'}
              effect="blur"
            />
            <div>
              <i
                className={cn("fa-solid fa-xmark", st.font)}
                onClick={() => setIsModalOpen(false)}
              >
              </i>
            </div>
          </div>
          <div
            className={cn(st.modal__btn, st.modal__btn_prev)}
            onClick={() => prevSlide()}
          >
          </div>
          <div
            className={cn(st.modal__btn, st.modal__btn_next)}
            onClick={() => nextSlide()}
          >
          </div>
        </div>)}
    </>
  )
}

export default BoxImage
