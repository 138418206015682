import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactForm, ContactSliceState } from "../../types/types";
import { sendContacts } from "./ContactAPI";



const initialState:ContactSliceState = {
   contactMessage: {},
   allMessages: []
}


const contactSlice = createSlice({
   name: 'contact',
   initialState,
   reducers: {
      addMessage: (state, action: PayloadAction<ContactForm>) => {
         state.allMessages.push(action.payload)
      }
   },
   extraReducers(builder) {
      builder.addCase(sendContacts.fulfilled, (state: any, action) => {
         state.contactMessage = action.payload
      })
   }
})

export default contactSlice.reducer
export const {addMessage} = contactSlice.actions