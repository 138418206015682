import {useState} from 'react'
import { useForm } from 'react-hook-form'
import { Navigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { getLogin } from '../../features/Login/LoginAPI'
import { Login } from '../../types/types'
import st from './login.module.scss'
import cn from 'classnames'

const LoginPage: React.FC = () => {
   const {register, handleSubmit, reset} = useForm<Login>()
   const dispatch = useAppDispatch();
   const {response} = useAppSelector((state: any) => state.login)
   const session: string | null = sessionStorage.getItem('id');
   const [isShowPassword, setIsShowPassword] = useState<boolean>(false)


   const save = (data: Login): void => {
      sessionStorage.clear()
      dispatch(getLogin(JSON.stringify(data)))
      reset()
   }

   const showPassword = (): void => {
    setIsShowPassword(!isShowPassword)
    const passInput: any = document.querySelector('#password')
    !isShowPassword ? passInput.type = 'text' : passInput.type = 'password'
   }

  return (
    <div className={st.container}>
      <div className={st.content}>
      <form onSubmit={handleSubmit(save)}>
        <h2 className={st.title}>Login</h2>
         <div className={st.input__box}>
          <input 
          type="text" 
          placeholder='Email*' 
          {...register('email', {required: true})}
          />
          </div>
         <div className={cn(st.input__box, st.passw__box)}>
          <label 
          className={st.icon__box}
          onClick={() => showPassword()}
          >
            {!isShowPassword ? <i className="fa-solid fa-eye"></i> : <i className="fa-solid fa-eye-slash"></i>}
            </label>
          <input type="password" 
          placeholder='Password*'
          id='password' 
          {...register('password', {required: true})}
          />
          </div>
         <div className={st.btn__box}><button>Save</button></div>
      </form>
      {response && session != null ? <Navigate to={'/admin'}/> : 
      response && session == null ? <p className={st.error}>Wrong login</p> : 
      <></>
      }
      </div>
    </div>
  )
}

export default LoginPage
